@import 'src/styles/utils';

.mg-nav-menu {
  @include icon-color($white);
  cursor: pointer;
}

.mg-nav-menu-menu {
  z-index: $z-app-bar;
  border-top: $border-gray-dark;

  .mg-paper-rounded {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
