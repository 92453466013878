@import 'src/styles/utils';

%mg-table-layout-fixed {
  table-layout: fixed;
}

%mg-table-base {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  thead {
    @extend %elevation-1;
    color: $white;
    z-index: $z-app-bar;

    .mg-icon-cell,
    .mg-table-sort-label-icon {
      @include icon-color($white);
    }

    th {
      background: $primary;
      font-weight: normal;
      text-align: start;
    }
  }

  th,
  td {
    padding-top: $spacer-2;
    padding-bottom: $spacer-2;
    text-align: left;
  }

  td {
    > svg {
      display: block;
    }
  }

  tr:not(:last-child) {
    td {
      border-bottom: $border-divider;
    }
  }

  tr.mg-has-error {
    td {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  tr.mg-selected {
    td {
      background: $bg;
    }
  }

  tr.mg-table-row-errors {
    td {
      padding: $spacer-1;
    }
  }

  th,
  td {
    &.mg-icon-cell {
      padding: $spacer-2;
      text-align: center;
      width: $checkbox-column-width;
    }
  }

  tr {
    @include opaque-on-hover('.mg-show-on-row-hover');
  }
}

%mg-table-rounded {
  thead {
    tr {
      background: $primary;
    }
    tr:first-child {
      th:first-child {
        border-top-left-radius: $border-radius;
      }
      th:last-child {
        border-top-right-radius: $border-radius;
      }
    }
  }
}

%mg-table-head-borders {
  th {
    &:not(:last-child):not(.mg-icon-cell):not(.mg-borderless) {
      border-right: $border-bg;
    }
  }
}

%mg-table-with-filter-row {
  thead {
    tr + tr {
      th {
        padding-top: $spacer-0;
      }
    }
  }
}

%mg-table-row-hover-highlight {
  tbody {
    tr:hover {
      td {
        background: $bg;
      }
    }
  }
}

.mg-table-compact {
  @extend %mg-table-base;
  @extend %mg-table-layout-fixed;
  @extend %mg-table-head-borders;

  th {
    @extend %body-2;
    padding: $spacer-1;
  }
}

.mg-table-small {
  @extend %mg-table-base;
  @extend %mg-table-layout-fixed;
  @extend %mg-table-head-borders;

  th,
  td {
    @extend %body-2;
    padding: $spacer-1;
  }

  .mg-table-sort-label {
    .mg-table-sort-label-icon {
      margin-bottom: $spacer-0;
    }
  }
}

.mg-table {
  @extend %mg-table-base;
  @extend %mg-table-layout-fixed;

  &:not(.mg-square-corners) {
    @extend %mg-table-rounded;
  }

  @extend %mg-table-head-borders;
  @extend %mg-table-with-filter-row;
  @extend %mg-table-row-hover-highlight;

  thead {
    th {
      padding: $spacer-3;

      &.mg-checkbox-col-header {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  td {
    padding-left: $spacer-3;
  }

  &.mg-dense {
    thead th,
    td {
      padding-left: $spacer-2;
      padding-right: $spacer-2;
    }
  }
}

.mg-table-multi-line-header {
  @extend %mg-table-base;
  @extend %mg-table-head-borders;

  &.mg-small {
    th {
      padding: $spacer-1 / 2;
    }
  }

  tr {
    td,
    th {
      text-align: center;
    }

    &:not(:first-child) {
      &:nth-child(3) {
        th {
          @extend %body-2;
        }
      }

      th,
      td {
        background-color: $white;
        color: $primary-dark;

        &.mg-bg-gray {
          background-color: $bg;
        }

        &.mg-day-warning {
          background-color: $warning;
        }

        &.mg-day-critical {
          background-color: $critical;
        }
      }

      td {
        border-bottom: $border-white;
      }
    }
  }
}

.mg-table-text-centered {
  thead th,
  tbody td {
    text-align: center;
  }
}

.mg-table-vertical-align-top {
  td {
    vertical-align: top;
    line-height: $input-height;
  }

  td {
    // hack to fix row height
    &.mg-icon-cell {
      position: relative;

      .mg-check-box-root {
        position: absolute;
        left: $spacer-2;
      }
    }
  }
}

.mg-align-top-cell-content {
  @extend %body-1;
  margin-top: $spacer-2;
}

.mg-col-min-width {
  min-width: 20px;
}

.mg-td-align-top {
  vertical-align: top;
}

/* use with care! */
.mg-table-scrollable {
  display: flex;
  flex-direction: column;
  /* override height for table in your table styles, e.g. calc(100% - other-stuff-height) */

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  tbody {
    display: block;
    overflow: auto;
    /* set height for tbody in your table styles, e.g. calc(100% - table-header-height) */
  }
}
