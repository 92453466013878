@import 'src/styles/utils';

.mg-menu-popper {
  z-index: $z-modal;
}

.mg-menu-paper {
  max-height: calc(100% - 96px);
  -webkit-overflow-scrolling: touch;
  
  .mg-dark & {
    background-color: $primary-dark;
  }
}

.mg-menu-list {
  outline: 0;
}
