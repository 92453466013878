@import 'src/styles/utils';

.mg-app-header {
  @extend %elevation-2;
  position: relative;
  z-index: $z-app-bar;
  background: $white;
}

.mg-app-header-info-wrapper {
  margin-top: $spacer-1 / 2;
}
