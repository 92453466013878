@import 'src/styles/utils';

.mg-divider-root {
  border: none;
  height: $border-width;
  flex-shrink: 0;
  background-color: $bg;

  &.mg-bg-color-gray-dark {
    background: $gray-dark;
  }
}

.mg-divider-vertical {
  width: $border-width;
  height: 100%;
}

.mg-divider-flex-item {
  height: auto;
  align-self: stretch;
}
