@import 'src/styles/utils';

.mg-user-table {
  th.mg-expand-icon-cell,
  td.mg-expand-icon-cell {
    padding: $spacer-0;
  }
  
  th.mg-col-type,
  td.mg-col-type {
    padding: $spacer-1;
  }
  
  tr {
    th,
    td {
      &:nth-child(1) {
        padding-right: $spacer-0;
      }
    }
  }

  @include col-width(mg-col-checkbox, $checkbox-column-width - $spacer-2);
  @include col-width(mg-col-type, 46px);
  @include col-width(mg-col-name, 25%);
  @include col-width(mg-col-customer, 30%);
  @include col-width(mg-col-roles, 45%);
  @include col-width(mg-col-actions, 120px);
}
