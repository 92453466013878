@import 'src/styles/utils';

.mg-pillow {
  @extend %body-2;
  display: inline-flex;
  background-color: $bg;
  padding: $spacer-1 $spacer-2;
  border-radius: $border-radius;
  align-items: center;
  height: $spacer-5;
  max-width: 100%;

  &:not(:last-child) {
    margin-right: $spacer-2;
  }

  &.mg-dark {
    background-color: $bg-dark;
  }

  &.mg-error {
    background-color: $error;
    color: $white;
  }

  &.mg-critical-light {
    background-color: $critical-light;
  }

  &.mg-warning-light {
    background-color: $warning-light;
  }

  &.mg-secondary-light {
    background-color: $secondary-light;
  }
}

.mg-pillow-text {
  @extend %overflow-ellipsis;
  min-width: 0;
}
