@import 'src/styles/utils';

.mg-input-base-input {
  &.mg-select-root {
    @extend %overflow-ellipsis;
    height: auto;
    padding-right: 26px;
    padding-left: 16px;
    cursor: pointer;
    user-select: none;

    &.mg-adorned{
      padding-right: 58px;
    }
  }
}

.mg-input-base-root.mg-disabled {
  .mg-select-root {
    cursor: default;
  }
}

.mg-select-icon {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  top: calc(50% - 12px);
  color: $gray;
  right: 7px;
  position: absolute;
  pointer-events: none;

  &.mg-open {
    @include icon-color($primary);
    transform: rotate(180deg);
  }
}

.mg-select-paper {
  max-height: 224px;
}

.mg-select-popper {
  z-index: $z-modal;
}
