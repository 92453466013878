@import 'src/styles/utils';

.mg-drag-handle {
  @extend %color-transition;
  @include icon-color-transition;
  cursor: grab;

  .sortable-chosen & {
    cursor: grabbing;
  }

  &:hover {
    @include icon-color($gray-dark);
  }
}

.sortable-chosen {
  @extend %elevation-1;
  background: $bg;
  td {
    border-color: transparent;
  }
  
  .mg-input-base-input {
    background: $white;
  }
}

.sortable-ghost {
  opacity: 0;
}
