@import 'src/styles/utils';

.mg-app-bar {
  background: $primary-dark;
  z-index: $z-app-bar;

  p {
    color: $white;
  }
}
