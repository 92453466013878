@import 'src/styles/utils';

.mg-pagination-wrapper {
  display: flex;
  list-style-type: none;
  color: $primary;
  margin: $spacer-0;

  .mg-pagination-link,
  .mg-pagination-break a {
    @extend %mg-link;
    height: $spacer-5;
    min-width: $spacer-5;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
      outline: 0;
    }
  }

  .mg-pagination-break a {
    user-select: none;
  }

  .mg-pagination-entry {
    @extend %body-2;
    text-transform: uppercase;
    margin-right: $spacer-1;

    &:first-child,
    &:last-child {
      margin-right: $spacer-2;
      margin-left: $spacer-2;

      .mg-pagination-link {
        width: auto;
      }
    }

    &.mg-active {
      .mg-pagination-link {
        background: $primary;
        border-radius: $spacer-5 / 2;
        color: $white;
      }
    }

    &.mg-disabled {
      .mg-pagination-link {
        color: $gray;
        cursor: default;
      }
    }
  }
}
