@import 'src/styles/utils';

.mg-input-adornment-root {
  display: flex;
  align-items: center;
  height: 0.01px;
}

.mg-input-adornment-position-start {
  margin-right: $spacer-2;
}

.mg-input-adornment-position-end {
  margin-left: $spacer-2;
}
