@import 'utils';

body {
  @extend %body-1, %font-family;
  color: $black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primary-dark;

  .mg-link & {
    @extend %mg-link;
  }
}

h1 {
  @extend %h1;
}

h2 {
  @extend %h2;
}

h3 {
  @extend %h3;
}

h4 {
  @extend %h4;
}

h5 {
  @extend %h5;
}

h6 {
  @extend %h6;
}

.mg-gray {
  color: $gray;
}

.mg-primary-dark {
  color: $primary-dark;
}

.mg-gray-dark {
  color: $gray-dark;
}

.mg-bg-dark {
  color: $bg-dark;
}

.mg-body-1 {
  @extend %body-1;
}

.mg-body-1-gray {
  @extend %body-1;
  color: $gray-dark;
}

.mg-body-1-primary {
  @extend %body-1;
  color: $primary;
}

.mg-body-1-white {
  @extend %body-1;
  color: $white;
}

.mg-body-2 {
  @extend %body-2;
}

.mg-body-2-gray {
  @extend %body-2;
  color: $gray-dark;
}

.mg-body-2-primary {
  @extend %body-2;
  color: $primary;
}

.mg-body-2-primary-bold {
  @extend %body-2;
  color: $primary;
  font-weight: bold;
}

.mg-body-2-white {
  @extend %body-2;
  color: $white;
}

.mg-error-text {
  color: $error;
}

.mg-height-h3 {
  height: $line-height-h3;
}

.mg-line-height-h4 {
  line-height: $line-height-h4;
}