@import 'src/styles/utils';

.mg-button {
  &:not(.mg-button-link) {
    @extend %button;
  }
  cursor: pointer;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  transition: background-color $transition, box-shadow $transition, border $transition;
  min-width: 64px;
  text-transform: uppercase;
  padding: ($spacer-2 - $border-width) $spacer-3;
  color: $primary;
  box-sizing: border-box;
  border-radius: 4px;
  border: $border-gray-light;
  @include icon-color($primary);

  &.mg-button-small {
    .mg-button-label {
      @extend %body-2;
    }
  }

  &:hover {
    background: rgba($primary, 0.08);
  }

  &:focus {
    background: rgba($primary, 0.16);
  }

  &.mg-borderless {
    border: $border-transparent;
  }

  &.mg-disabled {
    color: $gray-dark;
    pointer-events: none;
    @include icon-color($gray-dark);
  }
}

.mg-button-start-icon {
  width: $icon-size;
  height: $icon-size;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.mg-button-label {
  line-height: $spacer-4;
}

@mixin mg-button-color($color) {
  &:not(.mg-disabled) {
    @extend %elevation-1;
    color: $white;
    border: $border-width solid $color;
    background: $color;
    @include icon-color($white);
  }

  &:hover {
    $hover-bg-color: rgba($color, 0.92);
    border-color: $hover-bg-color;
    background: $hover-bg-color;
  }

  &:focus {
    $focus-bg-color: rgba($color, 0.84);
    background: $focus-bg-color;
  }

  &.mg-disabled {
    color: $gray-dark;
    background: $bg-dark;
    border: $border-bg-dark;
  }
}

.mg-button-secondary {
  @include mg-button-color($secondary);
}

.mg-button-error {
  @include mg-button-color($error);
}

.mg-button-plain {
  text-transform: none;
  border: none;
  box-shadow: none;
}

.mg-button-link {
  @extend %body-2;
  border: none;
  padding: $spacer-0;
  text-transform: none;
  min-width: 0;

  &:hover,
  &:focus {
    background: none;
  }
}
