﻿@import 'animations';

%mg-disabled {
  cursor: default;
  background: none;
  color: $gray;
  user-select: none;
  border-color: transparent;

  &:hover {
    background: none;
  }
}

%mg-link {
  text-decoration: none;
  color: $primary;
  cursor: pointer;
}

@mixin nth-col-width($n, $width) {
  th:nth-child(#{$n}),
  td:nth-child(#{$n}) {
    width: $width;
    min-width: $width;
  }
}

@mixin col-width($className, $width) {
  th.#{$className},
  td.#{$className} {
    width: $width;
    min-width: $width;
  }
}

@mixin opaque-on-hover($element-selector) {
  #{$element-selector} {
    @extend %opacity-transition;
    opacity: 0;
  }

  &:hover {
    #{$element-selector} {
      opacity: 1;
    }
  }
}
