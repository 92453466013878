@import 'src/styles/utils';

.mg-check-box-root {
  position: relative;
  display: inline-flex;
  box-sizing: border-box;
  z-index: 0;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 50%;
  @extend %color-transition;
  color: $gray;

  &.mg-checked,
  &.mg-indeterminate {
    color: $primary;
  }

  &:hover {
    background: rgba($gray, 0.08);
  }

  &.mg-disabled {
    cursor: default;
    pointer-events: none;
    background-color: transparent;
    color: $gray-light;

    &:hover {
      background: none;
    }
  }
}

.mg-check-box-label {
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
}

.mg-check-box-input {
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
}

.mg-check-box-svg {
  display: inline-block;
  flex-shrink: 0;
  user-select: none;
  fill: currentColor;
  width: 24px;
  height: 24px;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
