@import 'src/styles/utils';

.mg-calendar-header {
  .mg-calendar-month {
    @extend %body-1;
    font-weight: 400;
    color: $primary;
  }

  @include icon-color($primary);
}

.mg-calendar-weekdays {
  color: $gray-dark;
}

.mg-calendar-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;

  &.mg-selected {
    background: $primary;
    color: $white;
  }

  &.mg-today {
    border: $border-gray;
    padding: $spacer-1 - $border-width;
  }
}

.mg-calendar-row {
  &:not(:last-child) {
    padding-bottom: $spacer-1;
  }

  .mg-date-in-range {
    background-color: $primary-light;

    &:first-child,
    &.mg-first {
      @extend %mg-left-rounded-border;
    }

    &:last-child,
    &.mg-last {
      @extend %mg-right-rounded-border;
    }
  }

  .mg-day-entry:first-child {
    .mg-date-in-potential-range {
      border-left: $border-dashed-gray;
      margin-left: -1px;
      @extend %mg-left-rounded-border;
    }
  }

  .mg-day-entry:last-child {
    .mg-date-in-potential-range {
      border-right: $border-dashed-gray;
      margin-right: -1px;
      @extend %mg-right-rounded-border;
    }
  }

  .mg-date-in-potential-range {
    border-top: $border-dashed-gray;
    border-bottom: $border-dashed-gray;
    margin-top: -1px;
    margin-bottom: -1px;

    &.mg-first {
      border-left: $border-dashed-gray;
      margin-left: -1px;
      @extend %mg-left-rounded-border;
    }

    &.mg-last {
      border-right: $border-dashed-gray;

      margin-right: -1px;
      @extend %mg-right-rounded-border;
    }
  }
}
