@import 'src/styles/utils';

.mg-user-profile {
  @include icon-color($white);
  cursor: pointer;
  padding: ($spacer-2 + $spacer-1 / 2) $spacer-0;

  .mg-account-icon {
    margin-bottom: 1px; // adjust vertical alignment
  }
}

.mg-user-profile-menu {
  z-index: $z-app-bar;
}
