@import 'src/styles/utils';

.mg-nav-tab-active {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: $primary;
  }

  &.mg-icon-offset-right::after {
    right: $icon-size + $spacer-1;
  }
}
