@import 'src/styles/utils';

.mg-select-adornment-root {  
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  align-items: center;
  top: calc(50% - 12px);

  &.mg-select-adornment-position-end{
    right: 36px;
  }
}
