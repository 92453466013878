@import 'src/styles/utils';

.mg-nav-tab {
  @extend %mg-link;
  color: $white;
}

.mg-nav-tab-active {
  color: $primary;
}
