@import 'utils';

.mg-icon {
  min-width: $icon-size;
}

.mg-icon-white {
  @include icon-color($white);
}

.mg-icon-gray {
  @include icon-color($gray);
}

.mg-icon-success {
  @include icon-color($success);
}

.mg-icon-error {
  @include icon-color($error);
}

.mg-icon-warning {
  @include icon-color($warning);
}

.mg-icon-primary {
  @include icon-color($primary);
}

.mg-icon-primary-dark {
  @include icon-color($primary-dark);
}

.mg-icon-secondary {
  @include icon-color($secondary);
}

.mg-icon-secondary-light {
  @include icon-color($secondary-light);
}

.mg-icon-critical {
  @include icon-color($critical);
}

.mg-icon-critical-light {
  @include icon-color($critical-light);
}
