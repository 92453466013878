@import 'src/styles/utils';

.mg-overview-block-icon {
  @include icon-color-size($gray);
  fill:$gray;
}

.mg-overview-block-content {
  overflow: hidden;
  flex: 1 1 auto;
}