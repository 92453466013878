@import 'src/styles/utils';

.mg-breadcrumbs { 
  min-width: 0;
}

.mg-breadcrumb {
  @extend %overflow-ellipsis;
  flex-shrink: 1;
}

.mg-breadcrumb::after {
  color: $gray-dark;
  content: '/';
  display: inline-block;
  padding-left: $spacer-1;
  padding-right: $spacer-1;
}

