@import "src/styles/utils";

.mg-tabs-root {
  display: flex;
  overflow: hidden;
  flex-grow: 1;

  &.mg-variant-scrollable {
    overflow-x: scroll;
    flex: 1 1 auto;
    scroll-behavior: smooth;

    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .mg-tab-root {
      min-width: 185px;
      white-space: nowrap;
    }
  }
}
