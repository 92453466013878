﻿@import 'src/styles/utils/animations';

$icon-size: 24px;

@mixin icon-color($color) {
  path:last-child,
  circle {
    fill: $color;
  }
}

@mixin icon-color-size($color, $size: $icon-size) {
  height: $size;
  width: $size;
  min-width: $size;
  path:last-child,
  circle {
    fill: $color;
  }
}

@mixin icon-color-transition() {
  path:last-child,
  circle {
    transition: fill $transition;
  }
}
