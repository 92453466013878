﻿@import 'colors';

$border-radius: 4px;

$border-width: 1px;

$border-drop: 2px dashed;

$border-hint: 2px solid;

$border-transparent: $border-width solid transparent;
$border-white: $border-width solid $white;
$border-bg-dark: $border-width solid $bg-dark;
$border-bg: $border-width solid $bg;
$border-bg-light: $border-width solid $bg-light;
$border-gray-dark: $border-width solid $gray-dark;
$border-gray: $border-width solid $gray;
$border-gray-light: $border-width solid $gray-light;
$border-primary: $border-width solid $primary;

$border-dashed-gray: $border-width dashed $gray;
$border-dashed-primary: $border-width dashed $primary;

$border-divider: $border-bg;

%mg-left-rounded-border {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

%mg-right-rounded-border {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}