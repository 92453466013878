@import 'src/styles/utils';

.mg-dialog-actions-root {
  flex: 0 0 auto;
}

.mg-dialog-actions-spacing {
  > :not(:first-child) {
    margin-left: $spacer-2;
  }
}
