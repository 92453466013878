/* styleguide */
%font-family {
  font-family: 'Arial Regular', Arial, Helvetica, sans-serif;
}

$line-height-h1: 36px;
$line-height-h2: 32px;
$line-height-h3: 26px;
$line-height-h4: 26px;
$line-height-h5: 24px;
$line-height-h6: 20px;
$line-height-body-1: 24px;
$line-height-body-2: 20px;
$line-height-button: 16px;

%h1 {
  font-size: 28px;
  line-height: $line-height-h1;
  letter-spacing: 0.24px;
  font-weight: normal;
}

%h2 {
  font-size: 24px;
  line-height: $line-height-h2;
  letter-spacing: 0px;
  font-weight: normal;
}

%h3 {
  font-size: 20px;
  line-height: $line-height-h3;
  letter-spacing: 0.25px;
  font-weight: bold;
}

%h4 {
  font-size: 18px;
  line-height: $line-height-h4;
  letter-spacing: 0px;
  font-weight: normal;
}

%h5 {
  font-size: 16px;
  line-height: $line-height-h5;
  letter-spacing: 0.5px;
  font-weight: bold;
}

%h6 {
  font-size: 14px;
  line-height: $line-height-h6;
  letter-spacing: 0.5px;
  font-weight: bold;
}

%body-1 {
  font-size: 16px;
  line-height: $line-height-body-1;
  letter-spacing: 0.5px;
  font-weight: 400;
}

%body-2 {
  font-size: 14px;
  line-height: $line-height-body-2;
  letter-spacing: 0.25px;
}

%button {
  font-size: 14px;
  line-height: $line-height-button;
  letter-spacing: 1px;
}

%overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}