@import 'src/styles/utils';

.mg-form-control-label-root {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;

  &.mg-disabled {
    cursor: default;
    color: $gray;
  }
}

.mg-align-checkbox-left {
  margin-left: -10px;
}
