@import 'src/styles/utils';

.mg-modal-root {
  position: fixed;
  z-index: $z-modal;
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
}
