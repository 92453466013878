@import 'src/styles/utils';

.mg-tab-root {
  position: relative;
  padding-top: 9px;
  padding-bottom: 9px;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 2px;
  }

  &.mg-size-small {
    padding: $spacer-1;
  }

  &.mg-selected::after {
    background: $primary;
  }

  &:not(.mg-selected) {
    color: $gray-dark;

    @include icon-color($gray);
  }
}
