@import 'src/styles/utils';

.mg-radio-button-root {
  position: relative;
  display: inline-flex;
  box-sizing: border-box;
  z-index: 0;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 50%;
  @extend %color-transition;

  &:hover {
    background: rgba($gray, 0.08);
  }

  &.mg-disabled {
    cursor: default;
    pointer-events: none;
  }
}

.mg-radio-button-label {
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
}

.mg-radio-button-input {
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
}

.mg-radio-button-svg {
  display: inline-block;
  flex-shrink: 0;
  user-select: none;

  .mg-checked & {
    @include icon-color($primary);
  }
}

.mg-disabled {
  .mg-radio-button-svg {
    @include icon-color($gray);
  }
}
