@import 'src/styles/utils';

.mg-paper-root {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: $white;
}

.mg-paper-elevation1 {
  @extend %elevation-1;
}

.mg-paper-elevation2 {
  @extend %elevation-2;
}

.mg-paper-elevation8 {
  @extend %elevation-8;
}

.mg-paper-rounded {
  border-radius: 4px;
}
