%animate-all {
  transition: all 0.2s ease;
}

$transition: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
%bg-color-transition {
  transition: background-color $transition;
}

%opacity-transition {
  transition: opacity $transition;
}

%color-transition {
  transition: background-color $transition, color $transition;
}
