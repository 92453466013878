@import 'src/styles/utils';

.mg-list-item-root {
  @extend %overflow-ellipsis;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;

  &.mg-selected {
    background-color: $bg;
  }

  &.mg-size-medium {
    padding: $spacer-2 $spacer-3;
  }
  
  &.mg-size-small {
    padding: $spacer-0;
  }

  &.mg-list-item-link {
    padding: 0;
    a {
      text-decoration: none;
      color: $black;
      padding: $spacer-2 $spacer-3;
      width: 100%;
      display: inline-flex;
    }
  }

  &.mg-disabled {
    @extend %mg-disabled;
  }
}

.mg-list-item-button {
  outline: 0;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;

  &:hover {
    text-decoration: none;

    &:not(.mg-plain) {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
}

.mg-menu-item-root {
  width: auto;
}
