@import 'src/styles/utils';

$chip-height: 32px;

.mg-chip {
  padding: ($spacer-1 - $border-width) ($spacer-2 - $border-width);
  background: $bg-light;
  border: $border-bg-light;
  border-radius: $chip-height / 2;
  cursor: pointer;
  transition: border 0.2s ease, background 0.2s ease;
  height: $chip-height;
  min-width: $chip-height;
  display: flex;
  justify-content: center;
  line-height: $line-height-body-1;

  &.mg-disabled {
    @extend %mg-disabled;
  }

  &:hover {
    background: $primary-light;
  }
}

.mg-chip-selected {
  border: $border-primary;
  background: $primary-light;

  &.mg-disabled {
    border: $border-bg-dark;
    background: $bg;
    color: $gray-dark;
    pointer-events: none;
  }
}
