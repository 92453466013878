// Styleguide
// DTN palette:
// https://epam.invisionapp.com/d/main/#/console/18061887/407151824/preview

// make sure to keep these in sync
// with Colors in src\config.js

$black: #231f20;
$white: #ffffff;

$gray-dark: #707a8a;
$gray: #afbacc;
$gray-light: #c8d1e0;

$bg-dark: #e4e4e4;
$bg: #f5f5f5;
$bg-light: #fafafa;

$primary-dark: #102840;
$primary: #008ed1;
$primary-light: #e5f3fa;

$secondary-dark: #008076;
$secondary: #83ba51;
$secondary-light: #f2f8ed;

$error: #990000;
$critical: #e76666;
$warning: #fceb82;
$success: #83ba51;

// derived colors:
$critical-light: rgba($critical, 0.1);
$warning-light: rgba($warning, 0.2);
