.mg-list-root {
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
}

.mg-list-padding {
  padding-top: 8px;
  padding-bottom: 8px;
}
