$avatar-size: 36px;

.mg-avatar {
  min-width: $avatar-size;
  width: $avatar-size;
  height: $avatar-size;
  border-radius: 50%;
  overflow: hidden;
}

.mg-avatar-image {
  height: 100%;
  width: 100%;
  max-width: 100%;
}
