﻿// styleguide
%elevation-1 {
  box-shadow: 0 1px 1px 0 rgba(35, 31, 32, 0.14),
    0 2px 1px -1px rgba(35, 31, 32, 0.12), 0 1px 3px 0 rgba(35, 31, 32, 0.2);
}

%elevation-2 {
  box-shadow: 0 2px 2px 0 rgba(35, 31, 32, 0.14),
    0 3px 1px -2px rgba(35, 31, 32, 0.12), 0 1px 5px 0 rgba(35, 31, 32, 0.2);
}

%elevation-3 {
  box-shadow: 0 3px 4px 0 rgba(35, 31, 32, 0.14),
    0 3px 3px -2px rgba(35, 31, 32, 0.12), 0 1px 8px 0 rgba(35, 31, 32, 0.2);
}

%elevation-8 {
  box-shadow: 0 8px 10px 1px rgba(35, 31, 32, 0.14),
    0 3px 14px 2px rgba(35, 31, 32, 0.12), 0 5px 5px -3px rgba(35, 31, 32, 0.2);
}
