@import 'src/styles/utils';

@mixin hover-color($color) {
  &:hover {
    background-color: rgba($color, 0.1);

    svg {
      @include icon-color($color);
    }
  }
}

.mg-icon-button-root {
  @extend %body-1;
  @extend %bg-color-transition;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  flex: 0 0 auto;
  padding: 4px;
  overflow: visible;
  text-align: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;

  svg {
    @include icon-color($gray);
  }

  &.mg-color-white {
    svg {
      @include icon-color($white);
    }
  }

  @include hover-color($primary);

  &.mg-disabled {
    cursor: default;
    background: none;
    user-select: none;
    color: $gray-light;

    &,
    &:hover {
      @include icon-color($bg-dark);
    }
  }

  &.mg-size-small {
    width: 24px;
    height: 24px;
    padding: $spacer-0;
  }

  &.mg-color-error {
    @include hover-color($error);
  }

  &.mg-color-light {
    @include hover-color($bg-light);
  }

  &.mg-color-white {
    @include hover-color($white);
  }
}
