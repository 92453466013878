@import 'src/styles/utils';

.mg-calendar-header {
  .mg-calendar-title {
    @extend %body-1;
    color: $primary;
    padding: $spacer-0;

    &[disabled] {
      color: $gray;
      cursor: default;
    }
  }

  svg {
    @include icon-color($primary);
  }
}
