@import 'src/styles/utils';

.mg-nav-menu-item.mg-list-item-link {
  a {
    color: $white;
  }

  a.mg-active {
    color: $primary;
  }
}
