@import 'src/styles/utils';

.mg-input-label {
  @extend %body-1;
  @extend %overflow-ellipsis;
  color: $gray-dark;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(16px, 8px) scale(1);
  transform-origin: top left;
  pointer-events: none;
  z-index: 1;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    padding 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  max-width: calc(100% - 16px);
}

.mg-input-label-shrink {
  transform: translate(10px, -11px) scale(0.75);
  padding: 0 6px;
  background: $white;
}

.mg-focused {
  .mg-input-label {
    color: $primary;
  }
}

.mg-error {
  .mg-input-label {
    color: $error;
  }
}
