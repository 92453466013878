@import 'src/styles/utils';

.mg-expansion-panel {
  border-bottom: $border-divider;
}

.mg-expansion-panel-title {
  cursor: pointer;

  h3 {
    @extend %color-transition;
    color: $primary;
  }

  &:hover {
    h3 {
      color: $primary-dark;
    }

    .mg-expansion-panel-icon {
      @include icon-color($primary-dark);
    }
  }

  .mg-expansion-panel-icon {
    @include icon-color($primary);
  }
}

.mg-expansion-panel-contents {
  height: 0;
  display: none;
  user-select: none;
}

.mg-expansion-panel-icon {
  @include icon-color($gray);
}

.mg-expanded {
  .mg-expansion-panel-icon {
    transform: rotate(180deg);
  }

  .mg-expansion-panel-contents {
    height: auto;
    display: block;
  }
}
