@import 'src/styles/utils';

.mg-tenant-table {
  th.mg-expand-icon-cell,
  td.mg-expand-icon-cell {
    padding: $spacer-0;
  }

  @include col-width(mg-col-name, 19%);
  @include col-width(mg-col-description, 21%);
  @include col-width(mg-col-customer, 21%);
  @include col-width(mg-col-roles, 39%);
  @include col-width(mg-col-actions, 90px);
}
