@import "src/styles/utils";

.mg-table-sort-label {
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-start;
  flex-direction: inherit;
  align-items: center;
  max-width: 100%;

  .mg-table-sort-label-icon {
    height: 16px;
    width: 16px;
    min-width: 16px;
    margin: $spacer-1;
    margin-top: 0;
    @extend %animate-all;

    &.mg-asc {
      transform: rotate(180deg);
    }

    &.mg-desc {
      transform: rotate(0deg);
    }
  }
}