@import 'src/styles/utils';

@keyframes mg-circular-progress-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes mg-circular-progress-dash {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}

.mg-circular-progress-indeterminate {
  animation: mg-circular-progress-rotate 1.4s linear infinite;
}

.mg-circular-progress-root {
  display: inline-block;
  color: $primary;
}

.mg-circular-progress-svg {
  display: block;
}

.mg-circular-progress-circle {
  stroke: currentColor;
  fill: transparent !important;
}

.mg-circular-progress-circle-indeterminate {
  animation: mg-circular-progress-dash 1.4s ease-in-out
    infinite;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0px;
}
