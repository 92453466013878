@import 'src/styles/utils';

.mg-switch-root {
  position: relative;
  display: inline-flex;
  width: 58px;
  height: 38px;
  padding: 12px;
  box-sizing: border-box;
  z-index: 0;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}

.mg-switch-button {
  position: absolute;
  width: 38px;
  height: 38px;
  padding: 9px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: 1;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;

  &:hover {
    background: rgba($gray, 0.08);
  }

  &.mg-checked {
    transform: translateX(20px);
  }
}

.mg-switch-input {
  top: 0;
  left: -100%;
  width: 300%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  z-index: 1;
  position: absolute;
}

.mg-switch-thumb {
  width: 20px;
  height: 20px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  background: $white;
  display: inline-flex;
}

.mg-switch-track {
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: background 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 7px;
  background: $bg;
}

@mixin switch-checked-colors($thumb-color, $track-color) {
  .mg-checked {
    .mg-switch-thumb {
      background: $thumb-color;
    }

    & + .mg-switch-track {
      background: $track-color;
    }

    &:hover {
      background: rgba($thumb-color, 0.08);
    }
  }
}

.mg-switch-color-primary {
  @include switch-checked-colors($primary, $primary-light);
}
