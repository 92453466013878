@import 'src/styles/utils';

.mg-toast-container {
  top: $appbar-height + $subscription-detail-header-height + 12px;
  width: auto;
}

.mg-toast-close-icon {
  @include icon-color($gray);
  margin-right: $spacer-2;
}

.mg-toast-root {
  @extend %elevation-8;
  border-radius: $border-radius;
  min-height: auto;
  padding: $spacer-0;
}

.mg-toast-body {
  flex: 1 1 auto;
}