.mg-link-button {
  display: inline-flex;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin: 0;
  outline: 0;
  padding: 0;
  user-select: none;
  vertical-align: middle;

  &.mg-disabled {
    cursor: default;
  }
}
