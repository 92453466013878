@import 'src/styles/utils';

.mg-user-type-frame {
  @extend %elevation-1;
  background: $white;
  border-radius: $border-radius;
  border: $border-bg;

  &:hover {
    border: $border-primary;
  }

  &.selected {
    border: $border-hint;
    border-color: $primary;
  }
}
