@import 'src/styles/utils';

.mg-dropzone {
  border-radius: $spacer-2;
  border: $border-drop transparent;

  &.mg-highlighted {
    border: $border-drop $primary;
    background: $white;
    opacity: 0.5;
  }
}
