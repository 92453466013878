@import "src/styles/utils";

.mg-input-base-root {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  border-radius: $border-radius;
  background-color: $white;
  color: $black;
  @include icon-color($gray);

  &:hover:not(.mg-disabled) {
    .mg-notched-outline-root {
      border-color: $gray;
    }
  }

  &.mg-focused:not(.mg-disabled) {
    .mg-notched-outline-root {
      border-color: $primary;
      border-width: 2px;
    }
  }

  &.mg-error:not(.mg-disabled) {
    .mg-notched-outline-root {
      border-color: $error;
    }
  }

  &.mg-adorned-start {
    padding-left: $spacer-2;
    .mg-input-label:not(.mg-input-label-shrink) {
      left: $spacer-4;
    }
  }

  &.mg-adorned-end {
    padding-right: $spacer-2;
  }

  &.mg-disabled {
    @extend %mg-disabled;
    background: $bg;

    &:hover {
      background: $bg;
    }
  }

  &.mg-small {
    .mg-input-base-input {
      padding: $spacer-1/2 $spacer-1;
    }
  }
}

.mg-input-base-input {
  @extend %body-1;
  line-height: 20px !important; // IE fix
  font: inherit;
  height: 20px;
  color: currentColor;
  width: 100%;
  border: 0;
  margin: 0;
  display: block;
  padding: 10px $spacer-3;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  -webkit-tap-highlight-color: transparent;

  &::-ms-clear {
    display: none;
  }

  &::placeholder {
    color: $gray;
  }

  &:focus {
    outline: none;
  }

  &.mg-input-adorned-start {
    padding-left: 0;
  }

  &.mg-input-adorned-end {
    padding-right: 0;
  }

  .mg-input-label:not(.mg-input-label-shrink) + & {
    &::placeholder {
      opacity: 0;
    }
  }
}

.mg-notched-outline-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  pointer-events: none;
  transition: padding-left 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    border-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border: $border-gray;
  border-radius: $border-radius;

  .mg-error & {
    border-color: $error;
  }

  .mg-disabled & {
    border-color: $bg-dark;
  }
}

.mg-input-multiline {
  height: auto;
  resize: none;
  overflow-y: auto;
}
