@import 'src/styles/utils';

$dialog-top: 145px;

$dialog-sizes: (
  sm: 484px,
  md: 720px,
  lg: 968px,
  xl: 1280px,
);

@each $dialog-size, $dialog-width in $dialog-sizes {
  .mg-dialog-paper-#{$dialog-size} {
    width: $dialog-width;
  }
}

.mg-dialog-container {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
}

.mg-dialog-paper {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - #{(2 * $spacer-5) + $dialog-top});
  min-height: (2 * $spacer-5);
  top: $dialog-top;
  overflow: auto;
}
