@import 'src/styles/utils';

.mg-datepicker-item {
  width: 64px;
  border-radius: 16px;
  cursor: pointer;
  margin: 8px 0;

  &:hover {
    background: $primary-light;
  }
}
