// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $z-modal-content-box-shadow-xs.

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-grid-classes:                         true !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 16px !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: $spacer * .25, // 4px
    2: $spacer * .5, // 8px
    3: $spacer, // 16px
    4: $spacer * 1.5, // 24px
    5: $spacer * 2, // 32px
  ),
  $spacers
);

$negative-spacers: negativify-map($spacers) !default;

$spacer-0: map-get($spacers, 0);
$spacer-1: map-get($spacers, 1);
$spacer-2: map-get($spacers, 2);
$spacer-3: map-get($spacers, 3);
$spacer-4: map-get($spacers, 4);
$spacer-5: map-get($spacers, 5);

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// make sure to keep these in sync
// with mediaQueries variable in src\config.js
$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 720px,
  lg: 1024px,
  xl: 1280px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 1024px,
  md: 1280px,
  lg: 1920px,
  xl: 1920px
) !default;

$container-min-width: 463px;

// @include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           24px !default;
$grid-row-columns:            6 !default;


// Container padding

$container-padding-x: $grid-gutter-width !default;

