@import 'src/styles/utils';

.mg-table {
  .mg-drag-icon-column-width {
    width: $spacer-5;
    padding-left: $spacer-1;
  }
}

.mg-drag-handle {
  @include icon-color($gray);
}
