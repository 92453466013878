﻿// values are partially based on https://material-ui.com/customization/z-index/#z-index
$z-date-picker-popper: 2;
$z-splitter-arrow: 1000;
$z-map-tools: 1000;
$z-app-bar: 1100;
$z-modal: 1300;
$z-popup-on-modal: 1301;
$z-toast: 1400;
$z-loading: 1400;
$z-tooltip: 1500;
