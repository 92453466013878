/*!
 * Bootstrap Grid v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@import 'functions';
@import 'variables';

.container {
  min-width: $container-min-width;
}

@import 'mixins/breakpoints';
@import 'mixins/grid-framework';
@import 'mixins/grid';
@import 'mixins/utilities';

@import 'grid';

@import 'utilities';
// Only use the utilities we need
// stylelint-disable-next-line scss/dollar-variable-default
$utilities: map-get-multiple(
  $utilities,
  (
    'display',
    'order',
    'flex',
    'flex-direction',
    'flex-grow',
    'flex-shrink',
    'flex-basis',
    'flex-wrap',
    'justify-content',
    'align-items',
    'align-content',
    'align-self',
    'margin',
    'margin-x',
    'margin-y',
    'margin-top',
    'margin-right',
    'margin-bottom',
    'margin-left',
    'negative-margin',
    'negative-margin-x',
    'negative-margin-y',
    'negative-margin-top',
    'negative-margin-right',
    'negative-margin-bottom',
    'negative-margin-left',
    'padding',
    'padding-x',
    'padding-y',
    'padding-top',
    'padding-right',
    'padding-bottom',
    'padding-left'
  )
);

@import 'utilities/api';

@import 'variable-gutters';
