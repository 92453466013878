@import 'utils';

body,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

:focus {
  outline: none;
}

.mg-link {
  @extend %mg-link;
  @include icon-color($primary);
}

.mg-info-link {
  @extend %mg-link;
  border-bottom: $border-dashed-primary;
}


.mg-error-message {
  @extend %body-2;
  color: $error;
}

.mg-overflow-ellipsis {
  @extend %overflow-ellipsis;
}

.mg-overflow-ellipsis-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
}

.mg-overflow-y-auto {
  overflow-y: auto;
}

.mg-overflow-hidden {
  overflow-y: hidden;
}

.mg-full-width {
  width: 100%;
}

.mg-full-height {
  height: 100%;
}

.mg-full-width-height {
  width: 100%;
  height: 100%;
}

.mg-full-vh {
  height: 100vh;
}

.mg-rotate-left {
  transform: rotate(90deg);
}

.mg-rotate-right {
  transform: rotate(-90deg);
}

// use this to support animation
.mg-rotate-0 {
  transform: rotate(0deg);
  transition: transform $transition;
}

.mg-rotate-up {
  transform: rotate(180deg);
}

.mg-input-height {
  height: $input-height;
}

.mg-hidden {
  visibility: hidden;
}

.mg-text-align-center {
  text-align: center;
}

.mg-text-align-start {
  text-align: start;
}

::-webkit-scrollbar {
  width: $scroll-width;
  height: $scroll-width; // for horizontal scrolls
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $bg-dark;
  border: 6.5px solid transparent;
  border-radius: $scroll-width / 2;
  background-clip: content-box;
}

.mg-border-bottom-bg {
  border-bottom: $border-bg;
}

.mg-bg-light {
  background: $bg-light;
}

.mg-bg-white {
  background: $white;
}

// https://stackoverflow.com/questions/39490557/how-does-overflow-hidden-border-radius-on-a-container-cause-massive-slowdown
// TLDR; there is a bug in IE that causes scroll performance issues
// if any ancestor has both border-radius is > 0 and overflow: hidden
.mg-ie-scroll-performance-fix {
  border-radius: 0 !important;
}

.mg-thin-checkbox {
  margin-bottom: -$spacer-2;
  flex: 0 0 50%;
  min-height: $spacer-4 + 2 * $spacer-2;
  align-self: flex-start;
}

.mg-cursor-pointer {
  cursor: pointer;
}

.mg-overflow-wrap-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.mg-white-space-pre-wrap {
  white-space: pre-wrap;
}

.mg-popup-on-modal {
  z-index: $z-popup-on-modal;
}

.mg-flex-half-size {
  flex: 0 0 50%;
}

.mg-success-color {
  color: $success;
}

.mg-warning-color {
  color: $warning;
}

.mg-critical-color {
  color: $critical;
}