@import 'src/styles/utils';

@mixin color-style($color) {
  &::before {
    background: $color;
  }

  .mg-toast-icon {
    @include icon-color($color);
  }
}

.mg-toast-content {
  position: relative;
  padding: $spacer-3;

  &::before {
    content: '';
    width: $spacer-2;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }

  &.mg-info {
    @include color-style($primary);
  }

  &.mg-error {
    @include color-style($error);
  }

  &.mg-warning {
    @include color-style($warning);
  }

  .mg-toast-actions {
    margin-left: $spacer-2;
  }
}

.mg-toast-text {
  margin-left: $spacer-2;
}
