@import 'src/styles/utils';

.mg-tooltip-content {
  @extend %elevation-1;
  @extend %body-2;
  border-radius: $border-radius;
  background-color: $gray-dark;
  padding: $spacer-1 $spacer-2;
  margin: $spacer-2;
  color: $white;
  max-width: 240px;

  &:not(.mg-text-align-start) {
    text-align: center;
  }

  &.mg-size-lg {
    max-width: 480px;
  }
}

.mg-tooltip-wrapper {
  display: inline-block;

  &.mg-flex {
    display: flex;
  }

  &.mg-inline {
    display: inline;
  }
}

.mg-tooltip-popper {
  z-index: $z-tooltip;
}
